import {Store, useStore} from "vuex";
import {ISessionStorage, useSessionStorage} from "@/hooks/sessionStorage";
import { IUseLocalLoginStorage, useLocalStorage } from '@/hooks/localStorage'
import {IAppMenuState, IMenuItem} from "@/types/menus";
import {ILoginForm} from "@/types/login";
import {IPassword} from "@/types/system/account.d.ts";
import {IResult} from "@/types/share.d.ts";
import systemApi from "@/api/systemApi";
import request from '@/libs/request/index'
import {ILoginInfo} from "@/types/system/user.d.ts";

declare interface IUseSystem {
  setBreadCrumbs: (list: IMenuItem[]) => void;
  setCollapse: () => void;
  setMenuState: (data: IAppMenuState) => void;
  editPassword: (params: IPassword) => Promise<IResult>;
  clearToken: () => Promise<IResult>;
  getLoginToken: (params: ILoginForm) => Promise<IResult>;
  refreshLoginToken: () => Promise<IResult>;
  setLoginInfo: (info: ILoginInfo) => void;
  setExpireSeconds: (num: number) => void;
  editAvatar: (url: string) => Promise<IResult>;
}

function useSystem(): IUseSystem {
  const store: Store<any> = useStore(),
        { setSessionBreadCrumbs, setDefaultActive, setDefaultOpens }: ISessionStorage = useSessionStorage(),
        { setLocalLoginIngo, setLocalExpireSecond }: IUseLocalLoginStorage = useLocalStorage();

  //面包屑设置
  function setBreadCrumbs( list: IMenuItem[] ) {
    store.commit('setBreadCrumbs', list)
    setSessionBreadCrumbs(list)
  }

  //菜单展开缩放
  function setCollapse() {
    store.commit('setCollapse')
  }

  //菜单选择中和展开项设置
  function setMenuState(data: IAppMenuState) {
    store.commit('setMenuState',data)
    setDefaultActive(data.defaultActive)
    if(data.defaultOpens){
      setDefaultOpens(data.defaultOpens)
    }
  }

  //登录信息
  function setLoginInfo(info: ILoginInfo){
    store.commit('setLoginInfo',info)
    setLocalLoginIngo(info)
  }

  //修改密码
  function editPassword(params: IPassword): Promise<IResult>{
    return request.put(systemApi.agentAccountLoginPassword, params)
  }

  //修改头像
  function editAvatar(url: string): Promise<IResult>{
    return request.put(systemApi.agentAccountAvatar, {avatar: url})
  }

  //获取登录
  function getLoginToken(params: ILoginForm): Promise<IResult>{
    return request.jsonPost(systemApi.agentAuthCreateToken,params)
  }

  //获取登录
  function refreshLoginToken(): Promise<IResult>{
    return request.jsonPost(systemApi.agentAuthRefreshToken)
  }

  //退出登录
  function clearToken(): Promise<IResult>{
    return request.get(systemApi.agentAuthCleanToken)
  }

  //刷新用户token有效期
  function setExpireSeconds(num: number): void{
    store.commit('setExpireSeconds',num)
    setLocalExpireSecond(num)
  }
  return{
    setBreadCrumbs,
    setCollapse,
    setMenuState,
    editPassword,
    clearToken,
    getLoginToken,
    setLoginInfo,
    setExpireSeconds,
    refreshLoginToken,
    editAvatar
  }
}
export {
  IUseSystem,
  useSystem
}
