import axios from "axios";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import Cookies from '../cookie/index'
import qs from 'qs'
import { ElMessage } from "element-plus";
import {IResult} from "@/types/share.d.ts";

const service = axios.create({
  timeout: 30000,
})
// 发起请求拦截
service.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig =>{
  let token = Cookies.getItem('agentLoginToken')
  if(token){
    config.headers['Authorization'] = "Bearer " + token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 请求响应拦截
service.interceptors.response.use((response: AxiosResponse<any>): AxiosResponse<any> =>{
  if(response.data.errCode === 40101 || response.data.errCode === 40102 || response.data.errCode === 40103 || response.data.errCode === 30400){
    Cookies.remove('agentLoginToken')
    window.location.reload()
  }
  if(!response.data.success && response.config.responseType !== 'blob'){
    ElMessage.error( response.data.errMsg)
  }
  return response
}, (error) => {
  return Promise.reject(error)
})


function get(url: string): Promise<IResult>{
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'get'
    }).then(res=>{
      if(res.data.success){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })
}

function formPost(url: string, params?: any): Promise<IResult> {
  return new Promise((resolve,reject) => {
    service({
      url,
      method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      data: qs.stringify(params || {},{indices: false})
    }).then(res => {
      if(res.data.success){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err=>{
      reject()
    })
  })
}

function jsonPost(url: string, params?: any): Promise<IResult> {
  return new Promise((resolve,reject) => {
    service({
      url,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: params || {}
    }).then(res => {
      if(res.data.success){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err=>{
      reject()
    })
  })
}
function del(url: string): Promise<IResult>{
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'DELETE',
    }).then(res => {
      if(res.data.success){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err => {
      reject()
    })
  })
}
function put(url: string, params?: any): Promise<IResult> {
  return new Promise((resolve,reject) => {
    service({
      url,
      method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: params || {}
    }).then(res => {
      if(res.data.success){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err=>{
      reject()
    })
  })
}

//文件下载
// downloadFile(url){
//   return homestayService({
//     url,
//     responseType: 'blob',
//     method: 'GET',
//     timeout: 0,
//     ...config
//   })
// }

function exportData(url: string, params?: any): Promise<IResult>{
  return new Promise((resolve, reject) => {
    service({
      url,
      responseType: 'blob',
      method: 'POST',
      timeout: 0,
      data: params || {}
    }).then(res=>{
      if(res.status === 200){
        resolve(res.data)
      }else {
        reject()
      }
    }).catch(err=>{
      reject()
    })
  })
}
export default {
  get,
  formPost,
  jsonPost,
  exportData,
  del,
  put
}

