import {IMenuItem, IMenuTag} from "@/types/menus";

export declare interface ISessionStorage {
  getSessionTagList: () => IMenuTag[];
  setSessionTagList: (tagList: IMenuTag[]) => void;

  getSessionBreadCrumbs: () => IMenuItem[];
  setSessionBreadCrumbs: (list: IMenuItem[]) => void;

  getDefaultActive: ()=> string;
  getDefaultOpens: ()=> string[];
  setDefaultActive: ( active: string ) => void;
  setDefaultOpens: ( opens: string[] ) => void;

}
function useSessionStorage(): ISessionStorage {

  //标签导航获取/设置
  function getSessionTagList(): IMenuTag[] {
    return JSON.parse(sessionStorage.getItem('tagList') || "[{\"path\":\"/home\",\"name\":\"Home\",\"title\":\"欢迎首页\",\"lang\":\"home\"}]")
  }
  function setSessionTagList(tagList: IMenuTag[]): void {
    sessionStorage.setItem('tagList', JSON.stringify(tagList))
  }

  //面包屑获取/设置
  function getSessionBreadCrumbs(): IMenuItem[] {
    return JSON.parse(sessionStorage.getItem('breadCrumbs') || '[]')
  }
  function setSessionBreadCrumbs(list: IMenuItem[]): void {
    sessionStorage.setItem('breadCrumbs', JSON.stringify(list))
  }

  //菜单选中/展开获取
  function getDefaultActive(): string {
    return sessionStorage.getItem('defaultActive') || 'Home'
  }
  function getDefaultOpens(): string[] {
    return JSON.parse(sessionStorage.getItem('defaultOpens') || '[]')
  }

  //菜单选中/展开设置
  function setDefaultActive(active: string) {
    sessionStorage.setItem('defaultActive',active);
  }

  function setDefaultOpens(opens: string[]) {
    sessionStorage.setItem('defaultOpens',JSON.stringify(opens))
  }

  return {
    getSessionTagList,
    setSessionTagList,
    getSessionBreadCrumbs,
    setSessionBreadCrumbs,
    getDefaultActive,
    getDefaultOpens,
    setDefaultActive,
    setDefaultOpens,
  }
}
export{
  useSessionStorage,
}
