import { createStore } from 'vuex'
import app from "@/store/modules/app";
import user from '@/store/modules/user'

export default createStore({
  modules: {
    app,
    user
  }
})
