import { RouteRecordRaw } from 'vue-router'
import Main from "@/components/main/index.vue";
import ParentPage from '@/components/parentPage/ParentPage.vue'
const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    meta:{
      title: '登录页',
      hidden: true
    },
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/',
    redirect: 'home',
    name: 'Index',
    component: Main,
    meta:{
      title: '首页',
      icon: 'icon-home_fill_light'
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '欢迎首页',
          icon: 'icon-home_fill_light'
        },
        component: () => import('../views/home/Home.vue'),
      }
    ]
  },
  {
    path: '/customerManagement',
    name: 'CustomerManagement',
    component: Main,
    meta:{
      title: '客户管理',
      icon: 'icon-group_fill'
    },
    children: [
      {
        path: '/storeList',
        name: 'StoreList',
        meta: {
          title: '酒店列表',
          icon: 'icon-shopfill'
        },
        component: () => import('../views/customerManagement/StoreList.vue'),
      },
      {
        path: '/storeStatistic',
        name: 'StoreStatistic',
        meta: {
          title: '酒店统计',
          icon: 'icon-rankfill'
        },
        component: () => import('../views/customerManagement/StoreStatistic.vue'),
      },
      {
        path: '/storeStatisticDetail',
        name: 'storeStatisticDetail',
        meta: {
          title: '酒店统计详情',
          icon: 'icon-rankfill',
          hidden: true
        },
        component: () => import('../views/customerManagement/StoreStatisticDetail.vue'),
      }
    ]
  },
  {
    path: '/system',
    redirect: 'system',
    name: 'System',
    component: Main,
    meta:{
      title: '系统管理',
      icon: 'icon-settings_light'
    },
    children: [
      {
        path: '/account',
        name: 'Account',
        meta: {
          title: '账号管理',
          icon: 'icon-circlefill'
        },
        component: () => import('../views/system/Account.vue'),
      },
      {
        path: '/roleManagement',
        name: 'RoleManagement',
        meta: {
          title: '角色管理',
          icon: 'icon-circlefill',
          hidden: true
        },
        component: () => import('../views/system/RoleManagement.vue'),
      }
    ]
  },
  {
    path: '/test',
    name: 'Test',
    component: Main,
    meta:{
      title: '测试页面',
      icon: 'fa-wpforms',
      hidden: true
    },
    children: [
      {
        path: '/test1',
        name: 'Test1',
        meta: {
          title: '测试页面1',
          icon: 'fa-wpforms'
        },
        component: () => import('../views/test/Test1.vue'),
      },
      {
        path: '/test2',
        name: 'Test2',
        meta: {
          title: '测试页面2',
          icon: 'fa-wpforms'
        },
        component: () => import('../views/test/Test2.vue'),
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    meta:{
      hidden: true
    },
    component: () => import('../views/errorPage/404.vue')
  },
]
export default routes
