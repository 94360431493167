const dateFormat = (data: Date | number,fmt: string) => {
  // fmt：("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
  // ("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
  // ("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
  // ("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04,
  // ("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
  var date = new Date(data)
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    // "h+": date.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  var week = {
    "0": "\u65e5",
    "1": "\u4e00",
    "2": "\u4e8c",
    "3": "\u4e09",
    "4": "\u56db",
    "5": "\u4e94",
    "6": "\u516d"
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  if (/(E+)/.test(fmt)) {
    // @ts-ignore
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "\u661f\u671f" : "\u5468") : "") + week[this.getDay() + ""]);
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      // @ts-ignore
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt
}
export default {
  dateFormat
}
