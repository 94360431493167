import { RouteRecordRaw } from 'vue-router'
import { ComponentInternalInstance} from 'vue'
import { IMenuItem } from "@/types/menus";
let menuList:Array<IMenuItem>;
function _getMenuChildren(routes: Array<RouteRecordRaw>): Array<any>{
  menuList = routes.map(item=>{
    let menuItem: IMenuItem
    menuItem = {
      path: item.path,
      name: item.name,
      meta: item.meta,
    }
    if(item.children){
      menuItem.children = _getMenuChildren(item.children)
    }
    return menuItem
  })
  return menuList
}
function getMenuList(routes: Array<RouteRecordRaw>): Array<any>{
  menuList = routes.map(item=>{
    let menuItem: IMenuItem
    menuItem = {
      path: item.path,
      name: item.name,
      meta: item.meta,
    }
    if(item.children){
      menuItem.children =  _getMenuChildren(item.children)
    }
    return menuItem
  })
  return menuList
}
function getMenusLocaleValue(instance: ComponentInternalInstance, value: string): string {
  // @ts-ignore
  let { ctx } = instance,
      name: string = '';
  if(typeof ctx.$t === 'function'){
    name = ctx.$t(`message.menus.${value}`)
  }
  return name
}
function isShow(item: RouteRecordRaw): boolean{
 return item.meta ? !item.meta.hidden : false
}
export default {
  getMenuList,
  getMenusLocaleValue,
  isShow
}
