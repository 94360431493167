import { watch } from 'vue'
import {Store, useStore} from "vuex";
import { IMenuTag } from "@/types/menus";
import { useSessionStorage, ISessionStorage} from '../sessionStorage'

export declare interface IUseTagList {
  setTagList: ( list: IMenuTag[] ) => void;
  pushTag: (tag: IMenuTag) => void;
  removeTag: (tag: IMenuTag) => void;
  removeTagsAll: () => void;
  removeTagsOther: () => void;
}

function useTagList(): IUseTagList {
  const store: Store<any> = useStore()
  const { setSessionTagList }: ISessionStorage = useSessionStorage()
  watch(() => {
     return  store.state.app.tagList
    },
    (tagList: IMenuTag[])=>{
    setSessionTagList(tagList)
  })

  function setTagList(list: IMenuTag[]) {
    store.commit('setTagList', list)
  }
  function pushTag( tag: IMenuTag ) {
    store.commit('pushTag', tag)
  }
  function removeTag( tag: IMenuTag ) {
    store.commit('removeTag', tag)
  }
  function removeTagsAll() {
    store.commit('removeTagsAll')
  }
  function removeTagsOther() {
    store.commit('removeTagsOther')
  }
  return {
    setTagList,
    pushTag,
    removeTag,
    removeTagsAll,
    removeTagsOther
  }
}

export {
  useTagList,
}


