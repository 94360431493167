import {ILoginInfo} from "@/types/system/user.d.ts";

declare interface IUseLocalLoginStorage{
  setLocalLoginIngo: (info: ILoginInfo) => void;
  getLocalLoginIngo: () => ILoginInfo | {};
  setLocalExpireSecond: (num: number) => void;
  getLocalExpireSecond: () => number;
}

function useLocalStorage(): IUseLocalLoginStorage {
  function setLocalLoginIngo(info: ILoginInfo): void{
    localStorage.setItem('loginInfo',JSON.stringify(info))
  }
  function getLocalLoginIngo(): ILoginInfo | {}{
   return JSON.parse(localStorage.getItem('loginInfo') || '{}')
  }
  function getLocalExpireSecond(): number{
    return parseInt(localStorage.getItem('expireSecond') || '0')

  }
  function setLocalExpireSecond(num: number): void{
    localStorage.setItem('expireSecond', num + '')
  }
  return{
    setLocalLoginIngo,
    getLocalLoginIngo,
    getLocalExpireSecond,
    setLocalExpireSecond
  }
}


export {
  IUseLocalLoginStorage,
  useLocalStorage
}
