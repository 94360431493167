import routes from '@/router/router'
import {IAppMenuState,IMenuItem,IMenuTag} from "@/types/menus";
import utils from '@/libs/utils';
import {ILoginInfo} from "@/types/system/user";
const state = ():any => ({
  menuState: {
    defaultActive: '',
    defaultOpens: [],
  },
  tagList: [],
  breadCrumbs: [],
  collapse: false,
  loginInfo: {},
  expireSeconds: 0,
})

const getters = {
  menuList(): Array<IMenuItem> {
    return utils.getMenuList(routes)
  }
}

const mutations = {
  setMenuState(state: any, data: IAppMenuState): void{
    state.menuState.defaultActive = data.defaultActive
    state.menuState.defaultOpens = data.defaultOpens
  },
  setTagList(state: any, list: Array<IMenuTag>){
    state.tagList = list
  },
  pushTag(state: any, item: IMenuTag){
    state.tagList = [...state.tagList,item]
  },
  removeTag(state: any, tag: IMenuTag){
    state.tagList = state.tagList.filter((item: IMenuTag) => item.path !== tag.path)
  },
  removeTagsAll(state: any){
    state.tagList = state.tagList.filter((item:IMenuTag) => item.name === 'Home')
  },
  removeTagsOther(state: any){
    state.tagList = state.tagList.filter((item:IMenuTag) => item.path === '/home' || item.path === state.menuState.defaultActive)
  },
  setBreadCrumbs(state: any, data: Array<IMenuItem>){
    state.breadCrumbs = data
  },
  setCollapse(state: any){
    state.collapse = !state.collapse
  },
  setLoginInfo(state: any, info: ILoginInfo){
    state.loginInfo = info
  },
  setExpireSeconds(state: any, num: number){
    state.expireSeconds = num
  },
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}
