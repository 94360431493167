import { createRouter, createWebHashHistory, RouteLocationNormalized } from 'vue-router'
import routes from './router'
import Cookiet from '@/libs/cookie'
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to: RouteLocationNormalized , from:RouteLocationNormalized) => {
  const loginToken = Cookiet.getItem('agentLoginToken')
  if(to.path !== '/login' && !loginToken) router.replace({path: '/login'})
  return true
})


export default router
