import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/assets/css/iconfont.css'
import '@/assets/scss/transition.scss'
import 'default-passive-events'
import '@/assets/scss/index.scss'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus,{locale: zhCn,})
app.mount('#app')
