// @ts-ignore
import Cookie from 'js-cookie'
function setItem(key: string, value: string) {
  Cookie.set(key, value)
}
function getItem(key: string){
 return Cookie.get(key)
}
function remove(key: string){
  Cookie.remove(key)
}
export default {
  setItem,
  getItem,
  remove
}
