import config from '@/config/index'
// const BASE_URL = process.env.NODE_ENV === 'production' ? config.roomAdminMs : ''
const BASE_URL = config.roomAdminMs
export default {
  agentAccountEdit: BASE_URL + '/smarthotel-agent-ms/open/agent/account', //编辑账号
  agentAccountDetail: BASE_URL + '/smarthotel-agent-ms/open/agent/account/', //获取账号详情
  agentAccountDelete: BASE_URL + '/smarthotel-agent-ms/open/agent/account/', //删除账号
  agentAccountPassword: BASE_URL + '/smarthotel-agent-ms/open/agent/account/password', //修改密码
  agentAccountLoginPassword: BASE_URL + '/smarthotel-agent-ms/open/agent/account/login/password', //修改登录用户密码
  agentAccountQuery: BASE_URL + '/smarthotel-agent-ms/open/agent/account/query', //获取账号列表
  agentAccountSave: BASE_URL + '/smarthotel-agent-ms/open/agent/account/save', //添加账号
  agentRoleQuery: BASE_URL + '/smarthotel-agent-ms/open/agent/role/query', //分页查询角色信息
  agentRoleQueryAll: BASE_URL + '/smarthotel-agent-ms/open/agent/role/query', //查询角色信息
  agentRoleDelete: BASE_URL + '/smarthotel-agent-ms/open/agent/role/', //删除角色
  agentRoleDetail: BASE_URL + '/smarthotel-agent-ms/open/agent/role/', //获取角色详情
  agentRoleUpdate: BASE_URL + '/smarthotel-agent-ms/open/agent/role', //修改角色
  agentRoleSave: BASE_URL + '/smarthotel-agent-ms/open/agent/role', //添加角色
  agentAccountAvatar: BASE_URL + '/smarthotel-agent-ms/open/agent/account/avatar', //修改头像
  agentAuthCleanToken: BASE_URL + '/smarthotel-authentication/open/agent-auth/cleanToken', //清楚token
  agentAuthCreateToken: BASE_URL + '/smarthotel-authentication/open/agent-auth/createToken', //获取token
  agentAuthRefreshToken: BASE_URL + '/smarthotel-authentication/open/agent-auth/refreshToken', //刷新token
}
