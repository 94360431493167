import {IResult} from "@/types/share";

export const openDownloadDialog = (res: any, filename: string) => {
  if (window.navigator.msSaveBlob) {  // IE以及IE内核的浏览器
    try {
      window.navigator.msSaveBlob(res.data, filename)
    } catch (e) {
      console.log(e)
    }
  } else {
    let url = window.URL.createObjectURL(new Blob([res]))
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', filename)// 文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  }
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on: Function = (function () {
  let flag: Function | undefined = document.addEventListener
  if (flag) {
    return function (element: { addEventListener: (arg0: string, arg1: Function, arg2: boolean) => void; }, event: string, handler: Function) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element: { attachEvent: (arg0: string, arg1: Function) => void; }, event: string, handler: Function) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()


/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off: Function = (function () {
  let flag: Function | undefined = document.addEventListener
  if (flag) {
    return function (element: { removeEventListener: (arg0: string, arg1: Function, arg2: boolean) => void; }, event: string, handler: Function) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element: { detachEvent: (arg0: string, arg1: Function) => void; }, event: string, handler: Function) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

export default {
  openDownloadDialog,
  on
}
